import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Container,Row, Col } from "react-bootstrap";
import Img from 'gatsby-image';
import ElfsightWidget from "../Elfsight/ElfsightWidget";
import {GoogleReviewIcon} from "@components/icon/icon";
import { useQuery } from '@apollo/client';
import gql from "graphql-tag"
import PlayIcon from "../play-icon/play-icon";
import ReactPlayer from "react-player";
import getVideoId from "get-video-id";
import Slider from 'react-slick';
import { format } from 'date-fns';
import "slick-carousel/slick/slick.css";
import './ReviewsModule.scss';

const GET_ALL_REVIEWS = gql`
query GETREVIEWS{
    reviews {
        id
        name
        contents
        reviews_date
    }
}
`;

const ReviewsModule = (props) => {

  const { loading, error, data } = useQuery(GET_ALL_REVIEWS);

  console.log("datadata", data)
  
  const [show, setShow] = useState(false);
  const [play, setPlayVideo] = useState(false);
  const [showHTMLVideo, setHTMLVideo] = useState(false);

  const image_url = props?.reviewsData?.Widget_Video_Bg_Image?.internal.description
        ? props?.reviewsData?.Widget_Video_Bg_Image?.internal.description.replace("File ", "").replace('"', '').replace('"', '')
        : '';

  const url = typeof window !== "undefined" ? window.location.href : "";
  const trackerVideo = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Video Play Btn',
      'formType': url,
      'formId': 'Video Play',
      'formName': 'Video',
      'formLabel': 'Video'
    });
  }
  let video_url = '';    
  if (props?.reviewsData?.Widget_Embed_Video_URL) {
        const youtube_id = getVideoId(props?.reviewsData?.Widget_Embed_Video_URL);
        if (youtube_id.service === "youtube") {
            video_url = "https://www.youtube-nocookie.com/embed/" + youtube_id.id;
        } else {
            video_url = props?.reviewsData?.Widget_Embed_Video_URL;
        }
  }
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };



  return (
    <div className="reviews-module-wrap">
     <Container fluid>
        <Row>
          <Col xs={12} lg={5} className="left-column">
            <div className="reviews-carousel">
              <h2>{props?.reviewsData?.Percentage_value}</h2>
              <p className="reviews-text"><GoogleReviewIcon />{props?.reviewsData?.Total_reviews}</p>            

              <div className="slider-holder">
             <Slider {...settings}>
                    {data?.reviews && data?.reviews.map((item, key) => {
                      console.log("item?.reviews_date", item?.reviews_date)
                        let date = new Date(item?.reviews_date);
                        let formattedDate = format(date, 'dd MMM, yyyy');
                        return <>
                            <p className="review-quate">{item?.contents}</p>
                            <span className="author">{item?.name}</span>
                            <span className="date">{formattedDate}</span>
                        </>
                    })}
                </Slider>
              </div>

            </div>

            {/*<div className="elfsight-reviews-carousel">
                <ElfsightWidget widgetID={props?.reviewsData?.Carousel_Widget_Code} />
            </div>*/}
          </Col>          
          <Col xs={12} lg={{offset: 1, span: 6}} className="right-column">
          {showHTMLVideo &&
                <Modal
                    show={show}
                    onHide={() => setShow(false)}
                    dialogClassName="modal-video"
                    aria-labelledby="video-module"
                    backdropClassName="video-backdrop"
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactPlayer
                            url={video_url}
                            playing={play}
                            controls={true}
                            width='100%'
                            height='100%'
                            onPlay={trackerVideo}
                            onEnded={() => { setHTMLVideo(false) }}
                        />
                    </Modal.Body>
                </Modal>
            }
            {props?.reviewsData?.Widget_Embed_Video_URL ? 
                <>
                <Img fluid={props?.reviewsData?.Widget_Video_Bg_Image?.childImageSharp.fluid} alt="Anthony Pepe" className="right-image" />
                        
                <div className="video-play-btn"
                onClick={() => { setHTMLVideo(true); setPlayVideo(true); setShow(true); }}
                >
                      <PlayIcon size="large"/>
                </div>
                </>
                :
                <Img fluid={props?.reviewsData?.Widget_Video_Bg_Image?.childImageSharp.fluid} alt="Anthony Pepe" className="right-image" />
            }
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReviewsModule;